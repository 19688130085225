/* stylelint-disable no-descending-specificity */
@charset 'UTF-8';

body {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-quicksand-regular);
  gap: 0;
  min-height: 100vh;
}

main,
.footer {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

main {
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  flex-grow: 1;
  flex-shrink: 1;
}

.footer {
  --horizontal-padding: var(--gutter);
  --vertical-padding: calc(var(--gutter) / 2);

  background-color: var(--color-midnight-blue-900);
  color: var(--color-white);
  padding-bottom: var(--vertical-padding);
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  padding-top: var(--vertical-padding);
}

@media (width <= 480px) {
  .footer {
    padding-top: 0;
  }
}

@media (width <= 375px) {
  .footer {
    --horizontal-padding: calc(var(--gutter) / 2);
  }
}

/* ----------------------------------------------- */
main .content-wrapper,
main .content-wrapper-inner h1 {
  --freespace: 4vw;
}

main .content-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: calc(1024px + var(--gutter) * 2);
  padding-bottom: calc(var(--freespace) * 2);
  padding-left: var(--freespace);
  padding-right: var(--freespace);
  padding-top: calc(var(--top-app-bar-height) + var(--freespace));
}

main .content-wrapper.team {
  max-width: 1280px;
}

main .content-wrapper-inner {
  --horizontal-padding: calc(var(--gutter) * 2);
  --vertical-padding: calc(var(--gutter) / 2);

  background-color: var(--color-white);
  padding-bottom: calc(var(--vertical-padding) * 2);
  padding-left: var(--horizontal-padding);
  padding-right: var(--horizontal-padding);
  padding-top: var(--vertical-padding);
}

main .content-wrapper-inner h1 {
  margin-top: calc(var(--freespace) * 0.15 * -1);
}

/* stylelint-disable-next-line selector-max-compound-selectors */
main .content-wrapper-inner .page-headline-with-sharing-tools-cell h1 {
  margin-bottom: 0;
  margin-top: 0;
}

@media (width <= 1024px) {
  main .content-wrapper-inner {
    --horizontal-padding: var(--gutter);
  }
}

@media (width <= 768px) {
  main .content-wrapper {
    padding-bottom: var(--freespace);
  }

  main .content-wrapper-inner h1 {
    margin-top: 0;
  }
}

@media (width <= 480px) {
  main .content-wrapper-inner {
    --horizontal-padding: calc(var(--gutter) / 2);
  }
}

/* ----------------------------------------------- */
main.fullscreen {
  color: var(--color-white);
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

main.fullscreen .media {
  height: 100%;
}

main.fullscreen .media::after,
main.fullscreen .media::before {
  --position-xy: 0;
  --size: 100%;

  content: "";
  height: var(--size);
  left: var(--position-xy);
  position: absolute;
  top: var(--position-xy);
  width: var(--size);
  z-index: 0;
}

main.fullscreen .media::before {
  display: none;
}

main.fullscreen .media::after {
  --size: calc(100vw * 1.5);
  --curtain-horizontal-offset: 70vw;

  background-color: var(--color-black);
  clip-path: polygon(0 0, 100% 0, var(--hero-angle) 100%, 0% 100%);
  transform: translateX(calc(var(--curtain-horizontal-offset) * -1));
}

main.fullscreen[data-color-scheme="cobalt-blue"] .media::after {
  background-color: var(--color-cobalt-blue-900);
}

main.fullscreen[data-color-scheme="midnight-blue"] .media::after {
  background-color: var(--color-midnight-blue-800);
}

main.fullscreen[data-color-scheme="neon-rose"] .media::after {
  background-color: var(--color-neon-rose-700);
}

main.fullscreen[data-color-scheme="moss-green"] .media::after {
  background-color: var(--color-moss-green-700);
}

main.fullscreen[data-color-scheme="orange"] .media::after {
  background-color: var(--color-orange-600);
}

/* ----------------------------------------------- */
main.fullscreen .media img,
main.fullscreen .media video {
  --horizontal-offset: 0;
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: inline-flex;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  transform: translateX(calc(var(--horizontal-offset) * 1));
  width: var(--media-size);
  z-index: 0;
}

main.fullscreen .media img {
  --horizontal-offset: 22vw;
}

/* ----------------------------------------------- */
main.fullscreen .content {
  --horizontal-margin: 4vw;
  --position-xy: 0;

  left: var(--position-xy);
  padding-left: var(--horizontal-margin);
  padding-right: var(--horizontal-margin);
  padding-top: calc(var(--top-app-bar-height) + var(--gutter));
  position: absolute;
  top: var(--position-xy);
  width: 100%;
  z-index: 1;
}

main.fullscreen .content p {
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size-h6-fluid);
  line-height: var(--type-scale-perfect-fourth);
}

.shaped-content-object {
  --size: 60vw;

  clip-path: polygon(0 0, 100% 0, var(--hero-angle) 100%, 0% 100%);

  /* The shape-outside property works best with floated elements. */
  /* stylelint-disable-next-line property-disallowed-list */
  float: left;
  height: var(--size);
  padding-right: calc(var(--gutter) * 6);
  shape-outside: polygon(0 0, 100% 0, var(--hero-angle) 100%, 0% 100%);
  width: var(--size);
}

/* ----------------------------------------------- */
[data-color-scheme="midnight-blue"] .shaped-content-object p {
  text-shadow: var(--text-shadow-midnight-blue-dark);
}

[data-color-scheme="cobalt-blue"] .shaped-content-object p {
  text-shadow: var(--text-shadow-cobalt-blue-dark);
}

[data-color-scheme="neon-rose"] .shaped-content-object p {
  text-shadow: var(--text-shadow-neon-rose-dark);
}

[data-color-scheme="moss-green"] .shaped-content-object p {
  text-shadow: var(--text-shadow-moss-green-dark);
}

[data-color-scheme="orange"] .shaped-content-object p {
  text-shadow: var(--text-shadow-orange-dark);
}

/* ----------------------------------------------- */

@media (width <= 1024px) {
  main.fullscreen .media::after {
    --curtain-horizontal-offset: 55vw;
  }

  .shaped-content-object {
    --size: 75vw;
  }

  main.fullscreen .media img {
    --horizontal-offset: 20vw;
  }
}

@media (width <= 768px) {
  main.fullscreen .media::after,
  main.fullscreen .media img {
    left: auto;
    right: 0;
    top: var(--top-app-bar-height);
  }

  main.fullscreen .media::after {
    --curtain-horizontal-offset: -40vw;
    --size: calc(100vw * 2.75);

    top: calc(var(--top-app-bar-height) + var(--media-size));
  }

  .shaped-content-object {
    --size: auto;

    clip-path: unset;
    /* stylelint-disable-next-line property-disallowed-list */
    float: unset;
    height: auto;
    padding-right: 6vw;
    shape-outside: unset;
    width: unset;
  }

  main.fullscreen .media img {
    --horizontal-offset: 0;

    left: 0;
    right: auto;
    top: var(--top-app-bar-height);
    width: 100%;
    z-index: 1;
  }

  main.fullscreen {
    background-color: var(--color-midnight-blue-500);
  }

  main.fullscreen .content p:first-of-type {
    margin-top: 0;
  }

  main.fullscreen .media img,
  main.fullscreen .content,
  main.fullscreen .media::after {
    --media-size: calc(var(--top-app-bar-height) * 1.5);
  }

  main.fullscreen .content {
    padding-top: calc(var(--top-app-bar-height) + var(--media-size) + var(--gutter));
  }
}

@media (width <= 540px) {
  /* .shaped-content-object {
    --size: 130vw;
  } */

  main.fullscreen .media::after {
    --curtain-horizontal-offset: -50vw;
  }
}

@media (width <= 375px) {
  main.fullscreen .media::after {
    --curtain-horizontal-offset: -75vw;
  }

  .shaped-content-object {
    --size: 100vw;

    clip-path: none;
    /* stylelint-disable-next-line property-disallowed-list */
    float: none;
    height: auto;
    padding-right: 0;
    shape-outside: none;
    width: 100%;
  }
}

/* ----------------------------------------------- */
main:not(.fullscreen)::after {
  --position-xy: 0;
  --size: 100vh;
  --surface-color: var(--color-cobalt-blue-500);
  --hero-angle: 50%;

  background-color: var(--surface-color);
  bottom: var(--position-xy);
  clip-path: polygon(var(--hero-angle) 0, 100% 0, 100% 100%, 0% 100%);
  content: "";
  height: var(--size);
  position: fixed;
  right: var(--position-xy);
  transform: translateX(calc(100% - var(--hero-angle) * 1.8));
  width: var(--size);
  z-index: -1;
}

main[data-page-name="foundation"]:not(.fullscreen)::after,
main[data-page-name="philanthropists"]:not(.fullscreen)::after {
  --surface-color: var(--color-neon-rose-500);
}

/* @media (width <= 768px) {
  main:not(.fullscreen)::after {
    transform: translateX(calc(100% - var(--hero-angle) * 1.9));
  }
} */

@media (width <= 540px) {
  main:not(.fullscreen)::after {
    transform: translateX(calc(100% - var(--hero-angle) * 1.65));
  }
}

/* ----------------------------------------------- */
.content-block-with-image,
.content-block-with-image-cell {
  --image-width: 33%;
}

@media (width <= 1024px) {
  .content-block-with-image,
  .content-block-with-image-cell {
    --image-width: 320px;
  }
}

.content-block-with-image {
  align-items: stretch;
  column-gap: calc(var(--gutter) * 2);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: calc(var(--gutter) / 2);
  place-content: flex-start flex-start;
}

.content-block-with-image-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
}

.content-block-with-image-cell-content p:first-of-type {
  margin-top: 0;
}

.content-block-with-image-cell-image {
  aspect-ratio: 2 / 1;
  background-color: var(--color-white);
  flex-basis: var(--image-width);
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  width: var(--image-width);
}

.content-block-with-image-cell-image img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

.content-block-with-image-cell-image::after {
  --size: 266px;
  --position-y: calc(50% - var(--size) / 2);
  --surface-color: var(--color-midnight-blue-100);

  background-color: var(--surface-color);

  /* Fix optical bug with -1px position */
  bottom: var(--position-y);
  clip-path: polygon(var(--hero-angle) 0, 100% 0, 100% 100%, 0% 100%);
  content: "";
  height: var(--size);
  position: absolute;
  right: 0;
  transform: translateX(65%);
  width: var(--size);
}

/* ----------------------------------------------- */
.team-members,
.team-members-cell {
  --team-member-image-size: 220px;
}

.team-members {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: var(--gutter);
  margin-top: var(--gutter);
  place-content: stretch flex-start;
}

.team-members-cell {
  --surface-color: var(--color-midnight-blue-700);
  --on-surface-color: var(--color-white);

  align-self: auto;
  aspect-ratio: 1 / 1;
  background-color: var(--surface-color);
  color: var(--on-surface-color);
  flex-basis: 25%;
  flex-grow: 1;
  flex-shrink: 1;
  min-width: var(--team-member-image-size);
  overflow: hidden;
  position: relative;
  text-shadow: var(--text-shadow-midnight-blue-dark);
}

.team-members-cell[data-color-scheme="cobalt-blue"] {
  --surface-color: var(--color-cobalt-blue-800);

  text-shadow: var(--text-shadow-cobalt-blue-dark);
}

.team-members-cell[data-color-scheme="midnight-blue"] {
  --surface-color: var(--color-midnight-blue-700);
}

.team-members-cell img {
  --media-size: 100%;
  --media-position: calc(50% - var(--media-size) / 2);

  display: block;
  height: var(--media-size);
  left: var(--media-position);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: var(--media-position);
  width: var(--media-size);
  z-index: 0;
}

/* ----------------------------------------------- */
.page-headline-with-sharing-tools {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: calc(var(--gutter) / 4) var(--gutter);
  margin-bottom: calc(var(--gutter) / 2);
  place-content: flex-start flex-start;
}

.page-headline-with-sharing-tools-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
}

.page-headline-with-sharing-tools-cell:nth-child(2) {
  flex-grow: 0;
  flex-shrink: 0;
}

@media (width <= 786px) {
  .page-headline-with-sharing-tools {
    flex-wrap: wrap;
  }

  .page-headline-with-sharing-tools-cell:nth-child(1) {
    flex-basis: 100%;
    order: 1;
  }
}
