/* stylelint-disable declaration-no-important */

@charset 'UTF-8';

/*
  Custom CSS Reset
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

[data-document-ready="false"] * {
  transition: none !important;
}

html,
body {
  height: 100%;
  text-size-adjust: none;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

#root {
  isolation: isolate;
}

/*
  End Custom CSS Reset
*/

/* ----------------------------------------------- */
body {
  background-color: white;
  color: var(--color-black);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

img,
embed,
svg,
object,
video {
  border: 0;
  content-visibility: auto;
  display: inline-block;
  height: auto;
  image-rendering: high-quality;
  max-width: 100%;
  overflow: hidden;
}

img {
  width: 100%;
}

img,
svg {
  display: block;
  position: relative;
}

.img-eager {
  content-visibility: visible;
}

/* ----------------------------------------------- */
circle,
ellipse,
line,
path,
polygon,
polyline,
rect {
  /* shape-rendering: optimizespeed; */
  shape-rendering: geometricprecision;
}

rect {
  shape-rendering: crispedges;
}

/* ----------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
dl,
legend {
  display: inherit;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0;
  line-height: var(--type-scale-perfect-fourth);
  margin-bottom: 0;
  margin-top: 0;
  text-rendering: geometricprecision;
}

address {
  font-style: normal;
}

a {
  text-decoration: none;
}

a > img,
a > svg,
a:has(img),
a:has(svg) {
  display: inline-flex;
  outline: none !important;
}

/* ----------------------------------------------- */
button {
  appearance: none;
  background-color: transparent;
  background-image: none;
  border: none;
  cursor: pointer;
  outline-color: transparent;
  padding: 0;
}

/* a:focus,
input:focus {
  --outline-width: 1px;

  outline-color: var(--color-primary-light);
  outline-offset: calc(var(--outline-width) * 2);
  outline-style: solid;
  outline-width: var(--outline-width);
} */

/* ----------------------------------------------- */

.hide {
  border-width: 0 !important;
  font-size: 0 !important;
  left: -9999em;
  margin: 0 !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute;
  text-indent: 100% !important;
  white-space: nowrap !important;
  z-index: -100;
}

/* ----------------------------------------------- */
:root {
  --color-neutral-900: #201e22;
  --color-neutral-800: #413e43;
  --color-neutral-700: #605d62;
  --color-neutral-600: #747176;
  --color-neutral-500: #9d999f;
  --color-neutral-400: #bcb9be;
  --color-neutral-300: #e0dce2;
  --color-neutral-200: #eeebf1;
  --color-neutral-100: #f6f3f9;
  --color-neutral-050: #fcf8fe;
  --easing-point-to-point: ease-in-out;
  --easing-entering: ease-out;
  --easing-exiting: ease-in;
  --easing-color-or-opacity: linear;
  --easing-snap: cubic-bezier(0, 0.8, 0.2, 1);
  --letter-spacing-tight: -0.055em;
  --letter-spacing-all-caps: 0.055em;
  --rounded-corners-extra-extra-large: 24px;
  --rounded-corners-extra-large: 20px;
  --rounded-corners-large: 10px;
  --rounded-corners-medium: 8px;
  --rounded-corners-small: 4px;
  --shadow-color: 0deg 0% 0%;
  --shadow-strength: 40%;
  --shadow-elevation-low: 0 1px 1.1px hsl(var(--shadow-color) / var(--shadow-strength)),
    0 4px 4.5px -2.5px hsl(var(--shadow-color) / var(--shadow-strength));
  --shadow-elevation-medium: 0 1px 1.1px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 2)),
    0.1px 5.2px 5.9px -1.2px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 2)),
    0.2px 20px 22.5px -2.5px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 2));
  --shadow-elevation-high: 0 1px 1.1px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    0.1px 9.5px 10.7px -0.6px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    0.3px 22.7px 25.5px -1.2px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    0.6px 49.7px 55.9px -1.9px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3)),
    1.2px 100px 112.5px -2.5px hsl(var(--shadow-color) / calc(var(--shadow-strength) / 3));
  --speed-extra-slow: 500ms;
  --speed-slow: 400ms;
  --speed-normal: 300ms;
  --speed-fast: 200ms;
  --speed-extra-fast: 100ms;
  --speed-super-fast: 10ms;
  --type-scale-octave: 2;
  --type-scale-golden-ratio: 1.618;
  --type-scale-perfect-fourth: 1.333;
  --type-scale-major-second: 1.125;
}

/* .bottom-app-bar {
  --shadow-color: 208deg 56% 24%;
  --shadow-elevation-low: 0 1px 1.1px hsl(var(--shadow-color) / 32%),
    0 4px 4.5px -2.5px hsl(var(--shadow-color) / 32%);
  --shadow-elevation-medium: 0 1px 1.1px hsl(var(--shadow-color) / 20%),
    0.1px 5.2px 5.9px -1.2px hsl(var(--shadow-color) / 20%),
    0.2px 20px 22.5px -2.5px hsl(var(--shadow-color) / 20%);
  --shadow-elevation-high: 0 1px 1.1px hsl(var(--shadow-color) / 16%),
    0.1px 9.5px 10.7px -0.6px hsl(var(--shadow-color) / 16%),
    0.3px 22.7px 25.5px -1.2px hsl(var(--shadow-color) / 16%),
    0.6px 49.7px 55.9px -1.9px hsl(var(--shadow-color) / 16%),
    1.2px 100px 112.5px -2.5px hsl(var(--shadow-color) / 16%);
} */
