/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable declaration-no-important */
@charset 'UTF-8';

header:not(section header, nav header) {
  --position-xy: 0;

  left: var(--position-xy);
  position: fixed;
  top: var(--position-xy);
  width: 100%;
  z-index: 1000;
}

.top-app-bar,
.top-app-bar-cell,
.top-app-bar-brandmark-with-wordmark-and-descriptor::after,
.top-app-bar-brandmark-with-wordmark-and-descriptor::before,
.top-app-bar-nav-primary {
  --logo-width: 120px;
  --gap: calc(var(--gutter) / 2);
}

.top-app-bar,
main,
main.fullscreen .media img {
  --top-app-bar-height: 160px;
}

.top-app-bar {
  --horizontal-margin: 4vw;

  align-items: center;
  background-color: var(--color-midnight-blue-050);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 var(--gap);
  height: var(--top-app-bar-height);
  margin-left: var(--horizontal-margin);
  margin-right: var(--horizontal-margin);
  place-content: flex-start space-evenly;
  position: relative;
}

.top-app-bar-with-decoration {
  border-bottom: 2px solid var(--color-midnight-blue-900);
}

.top-app-bar-cell {
  --padding: var(--gap);
  --font-size: var(--font-size-body);
  --cell-width: calc(50% - var(--logo-width) - var(--gap));

  align-self: auto;
  flex-basis: var(--cell-width);
  flex-grow: 1;
  flex-shrink: 1;
  font-family: var(--font-family-havelock-black);
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding-left: var(--padding);
  padding-right: var(--padding);
  position: relative;
  width: var(--cell-width);
}

.top-app-bar-utility-row {
  flex-basis: 100%;
  padding-top: calc(var(--gap) / 2);
}

.top-app-bar-utility-row-bottom,
.top-app-bar-mobile-nav li {
  --ruler-color: var(--color-white);
}

.top-app-bar-utility-row-bottom {
  --padding: 0;

  padding-top: var(--gutter);
}

/* ----------------------------------------------- */

.top-app-bar-nav-primary,
.top-app-bar-brandmark {
  --brandmark-vertical-offset: calc(var(--gutter) * -1);
}

.top-app-bar-brandmark {
  --padding: 0;

  aspect-ratio: 1 / 1;
  flex-basis: var(--logo-width);
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: var(--brandmark-vertical-offset);
  text-align: center;
  width: var(--logo-width);
}

.top-app-bar-brandmark svg.favicon {
  --size: 100%;

  height: var(--size);
  width: var(--size);
}

.top-app-bar-brandmark-with-wordmark-and-descriptor::after,
.top-app-bar-brandmark-with-wordmark-and-descriptor::before {
  --font-size: calc(var(--logo-width) * 0.175);
  --width: calc(var(--logo-width) * 2.5);
  --position-x: calc(50% - var(--width) / 2);
  --position-y: calc(var(--font-size) / 2 - var(--gutter) * 0.85);
  --wordmark-vertical-offset: 0.65;

  bottom: var(--position-y);
  color: var(--color-midnight-blue-900);
  display: block;
  font-size: var(--font-size);
  height: var(--font-size);
  left: var(--position-x);
  line-height: var(--font-size);
  position: absolute;
  text-align: center;
  text-shadow: var(--text-shadow-white);
  transform: translateY(calc(var(--font-size) * var(--wordmark-vertical-offset) * -1));
  white-space: nowrap;
  width: var(--width);
  z-index: 1;
}

.top-app-bar-brandmark-with-wordmark-and-descriptor::before {
  content: "The Future Found";
  font-family: var(--font-family-havelock-bold);
  letter-spacing: -0.05em;
}

.top-app-bar-brandmark-with-wordmark-and-descriptor::after {
  --font-size: calc(var(--logo-width) * 0.1);
  --wordmark-vertical-offset: 0;

  content: "Investing in tomorrow";
  font-family: var(--font-family-havelock-regular);
}

/* ----------------------------------------------- */
.top-app-bar-nav-primary {
  justify-content: space-evenly !important;
  margin-top: var(--brandmark-vertical-offset) !important;
  padding-top: 9px !important;
}

/* ----------------------------------------------- */
.top-app-bar-nav {
  align-items: center;
  color: var(--color-cobalt-blue-700);
  column-gap: calc(var(--gutter) * 0.5);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  line-height: unset;
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  place-content: center flex-start;
}

.top-app-bar-nav.align-right {
  justify-content: flex-end;
}

.top-app-bar-nav li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.top-app-bar-nav li.investors {
  flex-grow: 1;
}

.top-app-bar-nav.utility-links {
  color: var(--color-midnight-blue-700);
  column-gap: var(--gutter);
  font-family: var(--font-family-quicksand-semi-bold);
  font-size: var(--font-size-body-extra-small);
}

.top-app-bar-left,
.top-app-bar-right {
  --horizontal-offset: 8vw;
}

.top-app-bar-left {
  padding-right: var(--horizontal-offset);
}

.top-app-bar-right {
  padding-left: var(--horizontal-offset);
}

/* ----------------------------------------------- */
.top-app-bav-dropdown-nav,
.top-app-bav-dropdown-nav a {
  --font-size: var(--font-size-body-small);
  --gap: calc(var(--font-size) * 1.5);
}

.top-app-bar-nav-primary a,
.top-app-bav-dropdown-nav a {
  color: var(--color-cobalt-blue-900);
  display: inline-flex;
  text-shadow: var(--text-shadow-white);
  transition-duration: var(--speed-fast);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.top-app-bar-nav-primary a {
  --vertical-padding: calc(var(--gap) * 0.5);

  display: block;
  padding-bottom: var(--vertical-padding);
  padding-top: var(--vertical-padding);
}

.top-app-bar-nav-primary a:hover,
.top-app-bav-dropdown-nav a:hover {
  color: var(--color-cobalt-blue-700);
  transition-duration: var(--speed-extra-fast);
}

.top-app-bar-nav-primary a[data-is-in-current-directory="true"] {
  color: var(--color-cobalt-blue-600);
}

.top-app-bar-nav-primary a:active,
.top-app-bar-nav-primary a:focus,
.top-app-bav-dropdown-nav a:active,
.top-app-bav-dropdown-nav a:focus {
  color: var(--color-orange-600);
  transition-property: none;
}

.top-app-bar-nav-primary a[aria-current="page"] {
  color: var(--color-cobalt-blue-700);
  transition-property: none;
}

.top-app-bav-dropdown-nav a[aria-current="page"] {
  pointer-events: none;
}

/* ----------------------------------------------- */
.top-app-bav-dropdown-nav {
  --surface-color: var(--color-midnight-blue-050);
  --width: 28ch;

  align-items: flex-start;
  background-color: var(--surface-color);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-bold);
  font-size: var(--font-size);
  left: auto;
  line-height: calc(var(--font-size) * 1.5);
  list-style: none;
  margin-bottom: 0;
  margin-left: calc(var(--gap) * -1);
  margin-top: 0;
  padding-bottom: 0;
  padding-left: var(--gap);
  padding-right: var(--gap);
  padding-top: 0;
  place-content: flex-start flex-start;
  pointer-events: none;
  position: absolute;
  row-gap: 0;
  top: auto;
  visibility: hidden;
  width: var(--width);
  z-index: -1;
}

.top-app-bav-dropdown-nav::after,
.top-app-bav-dropdown-nav::before {
  --border-color: var(--color-midnight-blue-200);
  --border-size: 4px;

  background-color: var(--border-color);
  content: "";
  display: block;
  position: absolute;
  z-index: 0;
}

.top-app-bav-dropdown-nav::before {
  --border-vertical-offset: 22px;

  height: calc(100% + var(--border-vertical-offset));
  left: calc(var(--border-size) * -1);
  top: calc(var(--border-vertical-offset) * -1);
  width: var(--border-size);
}

.top-app-bav-dropdown-nav::after {
  bottom: calc(var(--border-size) * -1);
  height: var(--border-size);
  left: calc(var(--border-size) * -1);
  width: calc(100% - var(--border-size));
}

.top-app-bav-dropdown-nav[data-is-open="true"] {
  pointer-events: auto;
  visibility: visible;
  z-index: 1000;
}

.top-app-bav-dropdown-nav li {
  align-self: auto;
  border-color: var(--color-cobalt-blue-300);
  border-style: solid;
  border-width: 0 0 1px;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
}

.top-app-bav-dropdown-nav li:last-child {
  border-width: 0;
}

/* ----------------------------------------------- */
.top-app-bar-mobile-nav {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-havelock-bold);
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center space-between;
  text-align: center;
  width: 100%;
}

/* stylelint-disable-next-line no-descending-specificity */
.top-app-bar-mobile-nav li {
  --vertical-ruler-width: 1px;

  align-self: auto;
  border-right: var(--vertical-ruler-width) solid var(--ruler-color);
  flex-basis: 25%;
  flex-grow: 1;
  flex-shrink: 1;
}

.top-app-bar-mobile-nav li:last-child {
  border-right: none;
}

/* stylelint-disable-next-line no-descending-specificity */
.top-app-bar-mobile-nav a {
  --font-size: var(--font-size-body-small);
  --padding: calc(var(--font-size) * 0.75);
  --surface-color: var(--color-midnight-blue-900);

  background-color: var(--surface-color);
  color: var(--color-white);
  display: block;
  font-size: var(--font-size);
  line-height: var(--font-size);
  padding-bottom: var(--padding);
  padding-left: var(--padding);
  padding-right: var(--padding);
  padding-top: var(--padding);
  text-shadow: var(--text-shadow-midnight-blue);
  transition-duration: var(--speed-normal);
  transition-property: background-color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.top-app-bar-mobile-nav a:hover {
  --surface-color: var(--color-cobalt-blue-700);

  text-shadow: var(--text-shadow-cobalt-blue);
  transition-duration: var(--speed-extra-fast);
}

.top-app-bar-mobile-nav a:active,
.top-app-bar-mobile-nav a:focus {
  --surface-color: var(--color-moss-green-800);

  text-shadow: var(--text-shadow-moss-green);
  transition-property: none;
}

/* ----------------------------------------------- */
@keyframes shimmer {
  0% {
    opacity: 0.75;
    transform: translateX(-125%);
  }

  100% {
    opacity: 0.25;
    transform: translateX(85%);
  }
}

.top-app-bar a.symbolmark::after {
  --animation-device-size: 66%;
  --position-xy: calc(50% - var(--animation-device-size) / 2);
  --vertical-offset: 3px;
  --animation-device-stage-position: -125%;
  --animation-device-color: var(--color-midnight-blue-050);

  animation-delay: 0s;
  animation-direction: normal;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: shimmer;
  animation-play-state: paused;
  animation-timing-function: var(--easing-snap);
  background-color: var(--animation-device-color);
  clip-path: polygon(45% 0, 100% 0, 55% 88%, 0 88%);
  content: "";
  height: var(--animation-device-size);
  left: var(--position-xy);
  opacity: 0.75;
  pointer-events: none;
  position: absolute;
  top: calc(var(--position-xy) + var(--vertical-offset));
  transform: translateX(var(--animation-device-stage-position));
  visibility: hidden;
  width: var(--animation-device-size);
}

.top-app-bar a.symbolmark:hover::after {
  animation-play-state: running;
  visibility: visible;
}

.top-app-bar a.symbolmark:not(:hover)::after {
  animation: none;
}

/* ----------------------------------------------- */

/* Widescreen styles */
@media (width <= 1536px) {
  .top-app-bar,
  .top-app-bar-cell,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::after,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::before,
  .top-app-bar-nav-primary {
    --horizontal-margin: 0;
  }
}

/* ----------------------------------------------- */
@media (width <= 1024px) {
  .top-app-bar,
  .top-app-bar-cell,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::after,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::before,
  .top-app-bar-nav-primary {
    --logo-width: 100px;
  }

  .top-app-bar,
  main,
  main.fullscreen .media img {
    --top-app-bar-height: 132px;
  }

  .top-app-bar-cell {
    font-size: var(--font-size-body-small);
  }

  .top-app-bar-brandmark-with-wordmark-and-descriptor::before {
    --wordmark-vertical-offset: 0.95;
  }

  .top-app-bar-brandmark-with-wordmark-and-descriptor::after {
    --wordmark-vertical-offset: 0.65;
  }
}

@media (width <= 768px) {
  .top-app-bar,
  .top-app-bar-cell,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::after,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::before,
  .top-app-bar-nav-primary {
    --logo-width: 90px;
  }

  .top-app-bar,
  main,
  main.fullscreen .media img {
    --top-app-bar-height: 110px;
  }

  .top-app-bar-left,
  .top-app-bar-right {
    --horizontal-offset: 4vw;
  }

  .top-app-bar-brandmark-with-wordmark-and-descriptor::after {
    display: none;
  }

  .top-app-bar-nav.utility-links {
    column-gap: calc(var(--gutter) / 2);
  }
}

@media (width <= 540px) {
  .top-app-bar-cell,
  .top-app-bar-nav.utility-links {
    font-size: var(--font-size-body-extra-small);
  }

  .top-app-bar,
  .top-app-bar-cell,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::after,
  .top-app-bar-brandmark-with-wordmark-and-descriptor::before,
  .top-app-bar-nav-primary {
    --logo-width: 76px;
  }

  /* .top-app-bar-brandmark-with-wordmark-and-descriptor::before {
    display: none;
  } */

  .top-app-bar-brandmark-with-wordmark-and-descriptor::before {
    --wordmark-vertical-offset: 1.25;
  }

  .top-app-bar-nav-primary,
  .top-app-bar-brandmark {
    --brandmark-vertical-offset: calc(var(--gutter) * 0.4 * -1);
  }

  .top-app-bar-left,
  .top-app-bar-right {
    --horizontal-offset: 0;

    bottom: 0;
    flex-basis: auto;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    position: absolute;
    width: 50%;
  }

  .top-app-bar-nav-primary {
    column-gap: 0;
    justify-content: space-evenly;
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .top-app-bar-left {
    left: 0;
  }

  .top-app-bar-right {
    right: 0;
  }

  .top-app-bar-nav-primary li {
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
  }

  .top-app-bar-nav.utility-links,
  .top-app-bar-nav li.investors {
    --position-x: var(--gap);
  }

  .top-app-bar-nav.utility-links {
    align-items: flex-end;
    display: inline-flex;
    flex-direction: column;
    position: absolute;
    right: var(--position-x);
    row-gap: 1px;
  }

  .top-app-bar-nav li.investors {
    left: var(--position-x);
    position: fixed;
  }

  .top-app-bar-nav li.donate {
    order: 1;
  }

  .top-app-bar,
  main,
  main.fullscreen .media img {
    --top-app-bar-height: 116px;
  }

  /* .top-app-bav-dropdown-nav {
    display: none;
  } */

  .top-app-bav-dropdown-nav,
  .top-app-bav-dropdown-nav::after {
    --width: 100vw;

    left: 0;
  }

  .top-app-bav-dropdown-nav {
    --surface-color: var(--color-midnight-blue-100);

    align-items: stretch;
    column-gap: var(--gutter);
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
    padding-bottom: calc(var(--gap) / 2);
  }

  .top-app-bav-dropdown-nav::before {
    display: none;
  }

  .top-app-bav-dropdown-nav::after {
    --border-size: 6px;

    width: var(--width);
  }

  .top-app-bav-dropdown-nav li {
    --width: 50%;

    border-color: var(--color-cobalt-blue-400);
    flex-basis: calc(var(--width) - var(--gap));
    max-width: var(--width);
    text-align: left;
    width: var(--width);
  }

  .top-app-bav-dropdown-nav li:last-child {
    border-width: 0 0 1px;
  }

  .top-app-bav-dropdown-nav a {
    color: var(--color-cobalt-blue-1000);
  }
}

@media (width <= 414px) {
  .top-app-bar-nav.utility-links,
  .top-app-bar-nav li.donate {
    --position-x: calc(var(--gap) / 2);
  }
}

/* ----------------------------------------------- */
[data-top-app-bar-is-collapsed="true"] .top-app-bar,
[data-top-app-bar-is-collapsed="true"] main,
[data-top-app-bar-is-collapsed="true"] main.fullscreen .media img {
  --top-app-bar-height: 80px;
}

[data-top-app-bar-is-collapsed="true"] .top-app-bar-brandmark-with-wordmark-and-descriptor::after,
[data-top-app-bar-is-collapsed="true"] .top-app-bar-brandmark-with-wordmark-and-descriptor::before {
  display: none;
}

[data-top-app-bar-is-collapsed="true"] .top-app-bar,
[data-top-app-bar-is-collapsed="true"] .top-app-bar-cell,
[data-top-app-bar-is-collapsed="true"] .top-app-bar-brandmark-with-wordmark-and-descriptor::after,
[data-top-app-bar-is-collapsed="true"] .top-app-bar-brandmark-with-wordmark-and-descriptor::before,
[data-top-app-bar-is-collapsed="true"] .top-app-bar-nav-primary {
  --logo-width: 86px;
}

[data-top-app-bar-is-collapsed="true"] .top-app-bar-cell,
[data-top-app-bar-is-collapsed="true"] .top-app-bar-nav.utility-links {
  font-size: var(--font-size-body-extra-small);
}

[data-top-app-bar-is-collapsed="true"] .top-app-bar-nav.utility-links {
  column-gap: calc(var(--gutter) / 2);
}

[data-top-app-bar-is-collapsed="true"] .top-app-bar-left,
[data-top-app-bar-is-collapsed="true"] .top-app-bar-right {
  --horizontal-offset: 0;
}

[data-top-app-bar-is-collapsed="true"] .top-app-bav-dropdown-nav {
  font-size: var(--font-size-body-small);
}

[data-top-app-bar-is-collapsed="true"] .top-app-bar-nav-primary {
  padding-top: 1px !important;
}

/* [data-top-app-bar-is-collapsed="true"] .top-app-bar {
  box-shadow: var(--shadow-elevation-medium);
} */
