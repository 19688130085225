/* stylelint-disable selector-max-compound-selectors */
@charset 'UTF-8';

.bottom-app-bar,
.bottom-app-bar-cell {
  --gap: calc(var(--gutter) / 2);
}

.bottom-app-bar {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--gap) calc(var(--gap) * 3);
  place-content: center space-between;
}

.bottom-app-bar-cell {
  --font-size: var(--font-size-body-extra-small);

  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  font-family: var(--font-family-quicksand-book);
  font-size: var(--font-size);
  line-height: var(--font-size);
}

.bottom-app-bar-cell-nav {
  font-family: var(--font-family-havelock-black);
}

.bottom-app-bar-cell-fine-print {
  border-top: 1px solid var(--color-midnight-blue-600);
  flex-basis: 100%;
  line-height: var(--type-scale-golden-ratio);
  padding-top: var(--gap);
}

/* ----------------------------------------------- */
.bottom-app-bar-cell-social,
.bottom-app-bar-cell-nav {
  flex-grow: 0;
  flex-shrink: 0;
}

/* ----------------------------------------------- */
.copyright-sign-off-device,
.copyright-sign-off-device-cell,
.copyright-sign-off-device-cell-monogram svg {
  --monogram-size: 50px;
}

.copyright-sign-off-device a {
  color: var(--color-white);
  text-decoration: none;
}

.copyright-sign-off-device {
  align-items: flex-start;
  column-gap: calc(var(--gutter) * 0.75);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: var(--font-family-quicksand-regular);
  font-size: var(--font-size-body-extra-small);
  line-height: var(--type-scale-perfect-fourth);
  place-content: flex-start flex-start;
  text-shadow: var(--text-shadow-midnight-blue-dark);
}

.copyright-sign-off-device span {
  display: inline;
  font-size: unset;
  line-height: unset;
}

.copyright-sign-off-device-cell {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  order: 1;
}

.copyright-sign-off-device-cell-fine-print {
  padding-top: 2px;
}

.copyright-sign-off-device-cell-monogram {
  aspect-ratio: 1 / 1;
  flex-basis: var(--monogram-size);
  flex-grow: 0;
  flex-shrink: 0;
  order: 0;
}

.copyright-sign-off-device-cell-monogram svg {
  margin-top: calc(var(--monogram-size) * 0.15 * -1);
}

.copyright-sign-off-device-cell-monogram svg.favicon .hallmark-left {
  fill: var(--color-cobalt-blue-600);
}

.copyright-sign-off-device-cell-monogram svg.favicon .hallmark-right {
  fill: var(--color-midnight-blue-500);
}

/* ----------------------------------------------- */
.footer-links {
  align-items: flex-start;
  column-gap: calc(var(--gap) * 2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  place-content: center flex-start;
}

.footer-links li {
  align-self: auto;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  line-height: var(--social-icon-size);
}

.footer-button-links li {
  /* Optically offset to accommodate box shadow on social buttons */
  padding-top: var(--rounded-corners-small);
}

/* stylelint-disable-next-line no-descending-specificity */
.footer-links a,
.footer-links button {
  color: var(--color-white);
  text-shadow: var(--text-shadow-midnight-blue-dark);
  transition-duration: var(--speed-normal);
  transition-property: color;
  transition-timing-function: var(--easing-color-or-opacity);
}

.footer-links a:hover,
.footer-links button:hover {
  color: var(--color-cobalt-blue-500);
  transition-duration: var(--speed-extra-fast);
}

.footer-links a:active,
.footer-links button:active,
.footer-links a:focus,
.footer-links button:focus {
  color: var(--color-white);
  transition-property: none;
}

/* ----------------------------------------------- */
a.footer-button-link.donate {
  --button-radius: var(--rounded-corners-small);
  --surface-color: var(--color-neon-rose-500);
  --on-surface-color: var(--color-neon-rose-050);

  background-color: var(--surface-color);
  border-radius: var(--button-radius);
  box-shadow: var(--shadow-elevation-low);
  color: var(--on-surface-color);
  padding: var(--button-radius);
  text-shadow: var(--text-shadow-neon-rose-dark);
  transition-duration: var(--speed-fast), var(--speed-fast);
  transition-property: background-color, box-shadow;
  transition-timing-function: var(--easing-color-or-opacity), var(--easing-color-or-opacity);
}

a.footer-button-link.donate:hover,
a.footer-button-link.donate:active,
a.footer-button-link.donate:focus {
  --on-surface-color: var(--color-white);
}

a.footer-button-link.donate:hover {
  --surface-color: var(--color-neon-rose-400);

  box-shadow: var(--shadow-elevation-medium);
  transition-duration: var(--speed-extra-fast), var(--speed-extra-fast);
}

a.footer-button-link.donate:active,
a.footer-button-link.donate:focus {
  --surface-color: var(--color-neon-rose-500);

  box-shadow: none;
  text-shadow: var(--text-shadow-neon-rose);
  transition-property: none;
}

/* ----------------------------------------------- */
a.footer-simple-text-link,
button.footer-simple-text-link {
  color: var(--color-white);
}

a.footer-simple-text-link:hover,
button.footer-simple-text-link:hover,
a.footer-simple-text-link:active,
button.footer-simple-text-link:active,
a.footer-simple-text-link:focus,
button.footer-simple-text-link:focus {
  color: var(--color-white);
}

/* ----------------------------------------------- */
@media (width <= 480px) {
  .bottom-app-bar {
    justify-content: space-around;
  }

  .footer-links {
    column-gap: calc(var(--gap) * 2.5);
    margin-top: var(--gap);
    place-content: center center;
    text-align: center;
  }

  .bottom-app-bar ul.social {
    column-gap: calc(var(--gutter) * 0.5);
  }

  .organization-registration-details {
    /* stylelint-disable-next-line declaration-no-important */
    place-content: center center !important;
  }

  .organization-registration-details dt::after {
    display: none;
  }

  .copyright-sign-off-device {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    row-gap: 0;
    text-align: center;
  }

  .copyright-sign-off-device-cell-monogram svg {
    height: var(--monogram-size);
    margin-top: 0;
    width: var(--monogram-size);
  }
}
